import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { BlueArrow } from "../../assets/svg"

import { Article } from "../../components"
import theme from "../../theme"

const CompliancePage: React.FC = () => {
  return (
    <Container>
      <Article
        title="Ensuring Compliance and Transparency"
        image={
          <StaticImage
            src="../../assets/images/compliance/compliance-1.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>
          Welcome to Workerslife&apos;s Compliance Page, where transparency and
          adherence to regulatory standards are our top priorities. Here, we
          provide you with essential legal documents and information to ensure
          clarity and trust.
        </p>
        <p>
          Explore our Conflict of Interest policy, learn about our Complaints
          procedures, understand our Privacy Policy, and be informed by our
          Website Disclaimer. We are committed to maintaining the highest
          standards of compliance, putting your interests and privacy first.
        </p>
        <p>
          Feel confident in your engagement with Workerslife, knowing that our
          commitment to ethical practices and regulatory requirements is
          unwavering.
        </p>
      </Article>
      <Article
        title="The legal documents..."
        image={
          <StaticImage
            src="../../assets/images/compliance/compliance-1.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
        imageLeft={true}
      >
        <p>
          <Link to="/conflict-of-interest">
            <LinkText>
              Conflict of Interest &nbsp; &nbsp;
              <BlueArrow />
            </LinkText>
          </Link>
        </p>
        <p>
          <Link to="/complaints">
            <LinkText>
              Complaints &nbsp; &nbsp;
              <BlueArrow />
            </LinkText>
          </Link>
        </p>
        <p>
          <Link to="/privacy-policy">
            <LinkText>
              Privacy Policy &nbsp; &nbsp;
              <BlueArrow />
            </LinkText>
          </Link>
        </p>
        <p>
          <Link to="/disclaimer">
            <LinkText>
              Website Disclaimer &nbsp; &nbsp;
              <BlueArrow />
            </LinkText>
          </Link>
        </p>
      </Article>
    </Container>
  )
}

export default CompliancePage

const Container = styled.div`
  padding: 0 58px 40px;
  display: grid;
  gap: 140px;
`

const LinkText = styled.a`
  padding-right: 27px;
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  @media screen and (max-width: ${theme.maxBreakpoints.sm}) {
    font-size: 23px;
  }
`
