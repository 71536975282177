import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import CompliancePage from "../sections/compliance/CompliancePage"

const Compliance: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Compliance"
        description="Explore Workerslife's compliance page for legal documents, conflict of interest, complaints, privacy policy, and website disclaimer. Ensure transparency and trust."
      />
      <CompliancePage />
    </PageWrapper>
  )
}

export default Compliance

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
